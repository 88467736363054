import '../sass/lsq2020.scss';
import './polyfills';
import '@babel/polyfill';
import 'custom-event-polyfill';
import 'whatwg-fetch';
import 'objectFitPolyfill';
// import GA from 'springload-analytics.js';

import dropdown from './modules/dropdown';
import mobileMenu from './modules/mobile.menu';
import readMore from './modules/read.more';
import onPageReady from './utils/onPageReady';
import { externaliseLinks } from './utils';

const config = Object.assign({
  // GA,
  header: '.page__header',
  mobileMenu: '.mobile-menu',
  mobileMenuTrigger: '.mobile-menu-toggle',
  readMoreTrigger: '.js-read-more-trigger',
  gallery: '[data-fancybox]',
  theme: 'lsq_2020',

}, window.APP_CONFIG);

function initialise() {
  window.THEME = config.theme;

  externaliseLinks();
  // GA.init();
  dropdown.init();
  mobileMenu.init(config);
  readMore.init(config);
  window.objectFitPolyfill();

  if (document.querySelectorAll('[data-reoako-id]')) {
    import('./modules/reoako.js')
      .then(({ default: reoakoInit }) => {
        reoakoInit();
      });
  }

  const chooseYourPriceCartEls = document.querySelectorAll('[data-cyp-delete]');
  if (chooseYourPriceCartEls.length) {
    import('./choose-your-price/choose-your-price-cart.js')
      .then(({ default: cypDeleteInit }) => {
        cypDeleteInit(chooseYourPriceCartEls);
      });
  }

  // dynamic by element detection
  if (document.getElementById('venue_index_map') || document.getElementById('venue_map')) {
    import(/* webpackChunkName: "map" */ './modules/googlemap.js')
      .then(({ default: map }) => {
        map.init(config);
      });
  }

  if (document.querySelectorAll('[data-shortlist-toggle]').length) {
    import(/* webpackChunkName: "shortlist" */ './modules/shortlist')
      .then(({ default: shortlist }) => {
        shortlist.init();
      }).catch(err => {
        console.log(err);
      });
  }

  const chooseYourPriceEls = document.querySelectorAll('[data-choose-your-price-booking]');
  if (chooseYourPriceEls.length) {
    import(/* webpackChunkName: `choose-your-price` */ './choose-your-price/index.js')
      .then(({ default: chooseYourPrice }) => {
        chooseYourPrice.init(chooseYourPriceEls);
      }).catch(err => {
        console.log(err);
      });
  }

  const chooseYourPriceSessionsEls = document.querySelectorAll('[data-choose-your-price-sessions]');
  if (chooseYourPriceSessionsEls.length) {
    import(/* webpackChunkName: `choose-your-price` */ './choose-your-price/index.js')
      .then(({ default: chooseYourPrice }) => {
        chooseYourPrice.init(chooseYourPriceEls);
      }).catch(err => {
        console.log(err);
      });
  }

  if (document.querySelectorAll('[data-fancybox]').length) {
    import(/* webpackChunkName: "photo-gallery" */ './modules/photo.gallery.js')
      .then(({ default: gallery }) => {
        gallery.init(config);
      }).catch(err => {
        console.log(err);
      });
  }


  if (document.querySelector('[data-video]')) {
    import(/* webpackChunkName: "video" */ './modules/video.js')
      .then(({ default: video }) => {
        video.init();
      }).catch(err => {
        console.log(err);
      });
  }

  if (document.querySelector('[data-opens-modal]')) {
    import(/* webpackChunkName: "modal" */ './modules/modal.js')
      .then(({ default: modal }) => {
        modal.init();
      }).catch(err => {
        console.log(err);
      });
  }

  if (document.querySelector('[data-copy-to-clipboard-wrapper]')) {
    import(/* webpackChunkName: "clipboard" */ './modules/clipboard.js')
      .then(({ default: clipboard }) => {
        clipboard.init();
      }).catch(err => {
        console.log(err);
      });
  }

  const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
  if (collapsibleEls.length > 0) {
    import(/* webpackChunkName: `collapsible` */ './modules/collapsible.js')
      .then(({ default: collapsibleInit }) => {
        collapsibleInit([...collapsibleEls]);
      });
  }
}

/* Initialise app */
onPageReady(initialise);
