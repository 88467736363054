function init() {
  document.querySelectorAll('[data-dropdown]').forEach(el => {
    const id = el.getAttribute('data-dropdown-id');
    const iconOriginal = el.getAttribute('data-icon-original');
    const iconDropdown = el.getAttribute('data-icon-dropdown');
    const iconElement = document.querySelector(`[data-icon-${id}]`);
    const childDropdown = document.querySelector(`[data-dropdown-child-${id}]`);
    if (id && childDropdown) {
      el.addEventListener('click', event => {

        event.preventDefault();

        if (iconElement && iconOriginal && iconDropdown) {
          if (iconElement.classList.contains(iconOriginal)) {
            iconElement.classList.remove(iconOriginal);
            iconElement.classList.add(iconDropdown);
          } else if (iconElement.classList.contains(iconDropdown)) {
            iconElement.classList.remove(iconDropdown);
            iconElement.classList.add(iconOriginal);
          }
        } else if (iconOriginal && iconDropdown) {
          if (el.classList.contains(iconOriginal)) {
            el.classList.remove(iconOriginal);
            el.classList.add(iconDropdown);
          } else if (el.classList.contains(iconDropdown)) {
            el.classList.remove(iconDropdown);
            el.classList.add(iconOriginal);
          }
        }

        document.querySelectorAll('[data-dropdown-child]').forEach(childEl => {
          if (!childEl.classList.contains('u-hidden') && childEl !== childDropdown) {
            childEl.classList.add('u-hidden');
          }
        });
        childDropdown.classList.toggle('u-hidden');
      });
    }

  });
}

export default { init };
